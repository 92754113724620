<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
          Toplam {{ totalItems }} müşteri bulundu.
        </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
        <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="5" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div>
          <div></div>
        </div>
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Müşteri Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
          @selectedItemIdsChanged="selectedItemIdsChanged"
          @openCreateModal="openInsertUpdate('')"
        >
          <template v-slot:item.isActive="{ item }">
            <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
          </template>
          <template v-slot:item.star="{ item }">
            <div v-html="$customFunctions.getIsStar(item.star)"></div>
          </template>
          <template v-slot:item.IsActive="{ item }">
            <b-badge variant="success" v-if="item.IsActive === true"
              >Ödendi</b-badge
            >
            <b-badge variant="danger" v-else>Ödenmedi</b-badge>
          </template>
          <template v-slot:item.IsDeleted="{ item }">
            <b-badge variant="success" v-if="item.IsDeleted === true"
              >Ödendi</b-badge
            >
            <b-badge variant="danger" v-else>Ödenmedi</b-badge>
          </template>
          <template v-slot:item.DogumTarihi="{ item }">
            <p class="text-dark" v-html="formatDate(item.DogumTarihi)"></p>
          </template>
          <template v-slot:item.yapilacakislem="{ item }">
            <b-button
              style="
                font-size: 11px;
                background-color: rgba(73, 50, 205, 0.527) !important;
                color: white;
              "
              variant="light"
              v-b-modal.modal-1
              >Lorem ipsum işi</b-button
            >
          </template>
          <template v-slot:item.isler="{ item }">
            <b-button
              style="
                font-size: 11px;
                background-color: rgba(47, 175, 147, 0.596) !important;
                color: white;
              "
              variant="light"
              v-b-modal.modal-2
              >Ödeme Bekleyen İşler</b-button
            >
          </template>
          <template v-slot:item.action="{ item }" class="p-0">
            <b-dropdown
              size="sm"
              no-caret
              dropleft
              variant="muted"
              style="z-index: 999"
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-ver text-dark"></i>
              </template>
              <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
              <b-dropdown-item @click="openInsertUpdate(item)"
                >Düzenle</b-dropdown-item
              >
              <b-dropdown-item
                v-if="item.IsDeleted === false"
                @click="openDeleteHandler(item)"
                >Sil</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:item.sonOdeme="{item}">
              <b-badge  v-b-popover.hover.top="'Ödeme Tarihi Gecikti!'" style="cursor: pointer;" pill variant="warning" class="p-3"><i class="fas fa-exclamation fa-sm mr-4" style="color: red;"></i> {{ item.sonOdeme }}</b-badge>
          </template>
        </List>
        <InsertUpdateModal
          :title="
            selectedItem == ''
              ? 'Müşteri Ödeme Kaydı Ekle'
              : 'Müşteri Ödemeyi Düzenle'
          "
          :show="showInsertUpdate"
          @closeDetail="closeInsertUpdate()"
          @confirm="confirmModal()"
          :resetForm="resetForm"
        >
          <InsertUpdateOperations
            :selectedItem="selectedItem"
            :confirmModal="confirmCreate"
            @resetInsertUpdateForm="resetInsertUpdateForm"
          />
        </InsertUpdateModal>
  
        <DetailModal
          title="Müşteri Detayı"
          :show="showDetail"
          @openInsertUpdate="openInsertUpdate(selectedItem)"
          @closeDetail="closeDetail"
        >
          <DetailView :selectedItem="selectedItem" />
        </DetailModal>
  
        <ImportModal
          :show="showImportModal"
          importApiEndpoint="user/import"
          @closeDetail="closeImportModal()"
          @updateList="getList"
          @downloadSampleFile="exportOperations(true)"
        />
  
        <DeleteOperation
          :url="deleteOperationUrl"
          :params="deleteIds"
          @updateList="getList"
          modalid="deleteCustomer"
        />
        <!-- Create and Actions Popups -->
      </div>
  
      <div>
        <b-modal v-model="openFullTextModal" title="BootstrapVue">
          <p class="my-4">Hello from modal!</p>
        </b-modal>
  
        <b-modal id="modal-1" title="Yapılacak İşler">
          <b-list-group >
            <b-list-group-item class="d-flex justify-content-between align-items-center"><p style="font-weight: 500; ">yapılacak iş 1 </p>
                <button class="btn" v-if="deleteJob" @click="updateJob()">
                <i class="fas fa-times" style="color: rgb(163, 25, 20);"></i>
              </button>
              <div v-else>
                <button class="btn" @click="updateJob()">
                <i class="fa fa-check" style="color: green;"></i>
              </button>
              <button class="btn" @click="updateJob()">
                <i class="fas fa-times" style="color: rgb(163, 25, 20);"></i>
              </button>
              </div>
            </b-list-group-item>
         
          </b-list-group>
          <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="sm" variant="success" @click="ok()">
          Tamam
           </b-button>
      </template>
        </b-modal>
        <b-modal id="modal-2" title="Ödeme Bekleyen İşler">
          <b-list-group >
            <b-list-group-item class="d-flex justify-content-between"><p style="font-weight: 500; ">İŞLER 1 </p><i class="fa fa-check" style="color: green;"></i></b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between"><p style="font-weight: 500; ">İŞLER 1 </p><i class="fa fa-check" style="color: green;"></i></b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between"><p style="font-weight: 500; ">İŞLER 1 </p><i class="fa fa-check" style="color: green;"></i></b-list-group-item>
            <b-list-group-item  class="d-flex justify-content-between"><p style="font-weight: 500;">İŞLER 2 </p><i class="fas fa-times" style="color: rgb(163, 25, 20);"></i></b-list-group-item>
          </b-list-group>
          <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="sm" variant="success" @click="ok()">
          Tamam
           </b-button>
      </template>
        </b-modal>
      </div>
      TEKRAR KONTROL EDİLECEK
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text {
    span {
      background-color: $dveb-divider;
      padding: 0.2rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
  </style>
  
  <script>
    import Swal from 'sweetalert2';
  import { mapGetters } from "vuex";
  import moment from "moment";
  
  export default {
    name: "customerList",
  
    data() {
      return {
        deleteJob: 0,
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames: ["Hazırlık", "Satış", "Devir"],
        header: [
          { text: "", value: "id", checkboxField: true, size: "70px" },
          // { text: '', value: 'star'  },
          { text: "Müşteri Adı", value: "musteriadi", size: "160px" },
          { text: "İş Ortağı", value: "isortagi", size: "160px" },
          { text: "Ödeme Tutarı", value: "odeme", size: "160px" },
          { text: "Yapılacak İşlem", value: "yapilacakislem", size: "210px" },
        //   { text: "Ödeme Bekleyen İşler", value: "isler", size: "200px" },
          { text: "Son Ödeme Tarihi", value: "sonOdeme", size: "160px" },
          { text: "Ödeme Durumu", value: "IsActive", size: "200px" },
          { text: "", value: "action" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList: [],
        list_length: 5,
        openFullTextModal: false,
      };
    },
    components: {
      Moretext: () => import("@/components/global/Moretext"),
    },
    created() {
      this.search = this.$route.query.search;
      this.search = this.search.toLowerCase();
      this.getList();
    },
    methods: {
        
      getList() {
        this.loading = false;
  
        this.items = [
          {
            id: "1",
            musteriadi: "Melisa Eldem",
            isortagi: "DveB",
            odeme: "2000₺",
            IsActive: true,
            sonOdeme: "07.07.2023",
            yapilacakislem: "lorem ipsum firması",
            // isler: "lorem ipsum işi",
          },
          {
            id: "2",
            musteriadi: "Rasim Karadeniz",
            isortagi: "DveB",
            odeme: "2000₺",
            IsActive: true,
            sonOdeme: "15.04.2023",
            yapilacakislem: "lorem ipsum firması",
            // isler: "lorem ipsum işi",
          },
          {
            id: "3",
            musteriadi: "Ahmet Berke Aydın",
            isortagi: "DveB",
            odeme: "2000₺",
            IsActive: false,
            sonOdeme: "27.02.2023",
            yapilacakislem: "lorem ipsum firması",
            // isler: "lorem ipsum işi",
          },
        ];
        // this.totalItems = 0;
        // this.loading = true;
  
        // let queryLimit = this.list_length ? this.list_length : 10;
        // let query = `musteriler_list?offset=${
        // this.page ? this.page : 0
        // }&limit=${queryLimit}&token=${this.myUser}&searchValue=Kiraci&column=MusteriTipi`;
  
        // this.$ApiService
        // .get(query)
        // .then((res) => {
        //     if (res.status == 200) {
        //     this.items = res.data.result;
        //     this.totalItems = res.data.result?.length;
        //     this.pagination = {
        //         current_page: this.page,
        //         total_pages: 3,
        //         total_items: this.totalItems,
        //     };
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
        // .finally(() => {
        //     this.loading = false;
        // });
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      confirmModal() {
        this.confirmCreate = true;
        setTimeout(() => {
          this.confirmCreate = false;
        }, 200);
      },
      resetInsertUpdateForm(closeInsertUpdate = false) {
        if (closeInsertUpdate) {
          this.closeInsertUpdate();
          this.getList();
        }
  
        this.resetForm = true;
        setTimeout(() => {
          this.resetForm = false;
        }, 200);
      },
      formatDate(date) {
        return moment(date).format("LL");
      },
      closeInsertUpdate() {
        setTimeout(() => {
          this.selectedItem = "";
        }, 100);
        this.showInsertUpdate = false;
      },
      openInsertUpdate(item) {
        this.selectedItem = item;
        this.showInsertUpdate = true;
      },
      closeDetail() {
        this.showDetail = false;
      },
      openDetail(item) {
        this.selectedItem = item;
        this.showDetail = true;
      },
      closeImportModal() {
        this.showImportModal = false;
      },
      openImportModal() {
        this.showImportModal = true;
      },
      openDeleteHandler(item) {
        this.deleteOperationUrl = `musteriler_delete`;
        this.deleteIds = { token: this.myUser, id: item._id.$oid };
        this.$bvModal.show("modal-deleteOperationdeleteCustomer");
      },
      selectedItemIdsChanged(payload) {
        this.listSelectedItemIds = payload;
      },
      actions(payload) {
        if (
          this.listSelectedItemIds === [] ||
          this.listSelectedItemIds.length == 0
        ) {
          this.$generateNotification(
            this,
            "warning",
            "İşlem yapılacak seçili kayıt bulunamadı!",
            "İşleme devam edilemiyor."
          );
        } else {
          if (payload == "allRemove") {
            this.deleteOperationUrl = `user`;
            this.deleteIds = this.listSelectedItemIds;
            this.$bvModal.show("modal-deleteOperation");
          } else if (payload == "allInActive" || payload == "allActive") {
            this.loading = true;
            this.$dbFunctions
              .update(`user/update-status`, {
                ids: this.listSelectedItemIds,
                isActive: payload == "allActive",
              })
              .then((res) => {
                this.$dbFunctions.setResponse(this, res);
                this.getList();
              })
              .catch((err) => {
                this.$dbFunctions.setResponse(this, err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else if (payload == "allExport") {
            this.exportOperations(false);
          }
        }
      },
      exportOperations(onlyHeaders) {
        this.loading = true;
        let payload = {
          Ids: this.listSelectedItemIds,
          onlyHeaders: onlyHeaders,
        };
  
        this.$dbFunctions
          .insertOrUpdate(`user/export`, "export", payload)
          .then((res) => {
            // this.$customFunctions.downloadFile(res.data);
  
            this.$generateNotification(
              this,
              "success",
              `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
              "Dosyanız hazır!"
            );
          })
          .catch((err) => {
            this.$dbFunctions.setResponse(this, err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      updateJob() {
        Swal.fire({
        title: 'İş Yapıldı mı ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteJob = !this.deleteJob
            Swal.fire(
            'Değişiklik Kaydedildi!',
            'Yaptığınız Değişiklikler Kaydedildi',
            'success'
            )
        }
        })
      }
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length: function () {
        this.getList();
      },
      $route: function () {
        this.search = this.$route.query.search;
        this.search = this.search.toLowerCase();
      },
    },
    computed: {
      ...mapGetters(["myUser"]),
    },
    components: {
      InsertUpdateOperations: () =>
        import("@/components/systemUser/modals/InsertUpdateOperations"),
      DetailView: () => import("@/components/customers/modals/DetailModal"),
    },
  };
  </script>
  